import { AxiosPromise } from 'axios';
import { omit } from 'lodash';
import axios from '@/api/service';
import URLs from '@/api/service/urls';
import {
  ApplicationTeamRole,
  ApplicationTeamRoleCreateDataDto,
  ApplicationTeamRoleUpdateDataDto,
} from '@/types/application-user-roles.types';

export function getApplicationTeamRoles(): AxiosPromise<ApplicationTeamRole[]> {
  return axios.get(URLs.ApplicationTeamRoles, {});
}

export function createApplicationTeamRole(
  data: ApplicationTeamRoleCreateDataDto,
): AxiosPromise<ApplicationTeamRole> {
  return axios.post(URLs.ApplicationTeamRoles, data, {});
}

export function deleteApplicationTeamRole(uniqueKey: string): AxiosPromise<boolean> {
  return axios.delete(URLs.ApplicationTeamRolesUpdate, {
    params: { id: uniqueKey },
  });
}

export function updateApplicationTeamRole(
  data: ApplicationTeamRoleUpdateDataDto,
): AxiosPromise<ApplicationTeamRole> {
  const { uniqueKey } = data;
  return axios.put(URLs.ApplicationTeamRolesUpdate, omit(data, 'uniqueKey'), {
    params: { id: uniqueKey },
  });
}
