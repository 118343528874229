
import { Component, Vue } from 'vue-property-decorator';
import { Promised } from 'vue-promised';
import { AxiosError } from 'axios';
import { ValidationObserver } from 'vee-validate';
import GButton from '@/components/gsk-components/GskButton.vue';
import GImageUpload from '@/components/gsk-components/GskImageUpload.vue';
import MaxWidth from '@/components/MaxWidth.vue';
import DeleteDialog from '@/components/dialogs/DeleteDialog.vue';
import ValidatedFormDialog from '@/components/dialogs/ValidatedFormDialog.vue';
import {
  getApplicationTeamRoles,
  createApplicationTeamRole,
  deleteApplicationTeamRole,
  updateApplicationTeamRole,
} from '@/api/application-team-roles.api';
import { TextField, SelectField, PeoplePickerField, AutoCompleteField } from '@/components/form/form.types';
import {
  ApplicationTeamRole,
  ApplicationTeamRoleCreateDataDto,
  ApplicationTeamRoleUpdateDataDto,
} from '@/types/application-user-roles.types';
import { Team } from '@/types/teams.types';
import { openErrorSnackbar } from '../../utils/components';
import { EnumsModule } from '../../store/modules/enums.module';
import { TeamsModule } from '../../store/modules/teams.module';

type NewRoleForm = [AutoCompleteField<'team'>, SelectField<'mnemonic'>];

type EditRoleForm = [TextField<'uniqueKey'>, TextField<'teamId'>, SelectField<'mnemonic'>];

@Component({
  components: {
    GButton,
    MaxWidth,
    Promised,
    ValidatedFormDialog,
    DeleteDialog,
    GskImageUpload: GImageUpload,
  },
})
export default class ApplicationTeamRolesView extends Vue {
  newRoleOpen = false;
  open = false;
  roles: Promise<ApplicationTeamRole[]> | null = null;

  $refs!: {
    newRoleDialog: InstanceType<typeof ValidationObserver>;
  }

  newRoleForm: NewRoleForm = [
    {
      key: 'team',
      label: 'Select Team',
      type: 'autocomplete',
      value: {
        label: '',
        value: ''
      },
      choices: [],
      required: true,
      validation: {
        rules: {
          required: true,
        },
      },
      customValidator: this.validateTeamSelection,
    },
    {
      key: 'mnemonic',
      label: 'Role Mnemonic',
      type: 'select',
      value: '',
      required: true,
      options: Object.keys(EnumsModule.enums.role),
      validation: {
        rules: {
          required: true,
        },
      },
    },
  ];

  validateTeamSelection(val: any) {
    const value = String(val?.value).trim();
    return Promise.resolve(value ? '' : 'The Select Team field is required');
  }


  onCreateRoleDialogClosed() {
    for (const field of this.newRoleForm) {
      if (field.type === 'autocomplete') {
        field.value = {
          label: '',
          value: '',
        };
      } else if (field.type === 'select') {
        field.value = '';
      }
    }
  }

  get newRoleFormData(): ApplicationTeamRoleCreateDataDto {
    const out: ApplicationTeamRoleCreateDataDto = {
      teamId: 0,
      mnemonic: '',
    };
    this.newRoleForm.forEach(f => {
      if (f.key === 'mnemonic') {
        out.mnemonic = f.value;
      } else if (f.key === 'team') {
        out.teamId = Number(f.value.value);
      }
    });
    return out;
  }

  createRole() {
    createApplicationTeamRole(this.newRoleFormData)
      .then(() => {
        this.newRoleOpen = false;
        this.load();
        this.onCreateRoleDialogClosed();
      })
      .catch(err => {
        openErrorSnackbar.call(this, err.response?.data?.message || err.response?.data?.error || err.toString());
      });
  }

  editRoleOpen = false;
  editRole: ApplicationTeamRoleUpdateDataDto | null = null;
  editRoleForm: EditRoleForm | null = null;
  setEditRole(role: ApplicationTeamRole) {
    this.editRole = role;
    this.editRoleForm = [
      {
        key: 'uniqueKey',
        label: 'Unique Key',
        type: 'text',
        value: role.uniqueKey,
        required: true,
        attrs: {
          maxLength: '50',
          disabled: '1',
          hidden: '1',
        },
        validation: {
          rules: 'required|max:50',
        },
      },
      {
        key: 'teamId',
        label: 'Team',
        type: 'text',
        value: role.teamName,
        required: true,
        attrs: {
          maxLength: '50',
          disabled: '1',
        },
        validation: {
          rules: 'required|max:50',
        },
      },
      {
        key: 'mnemonic',
        label: 'Role Mnemonic',
        type: 'select',
        value: role.mnemonic ?? '',
        required: true,
        options: Object.keys(EnumsModule.enums.role),
        validation: {
          rules: 'required',
        },
      },
    ];
    this.editRoleOpen = true;
  }

  get editRoleFormData(): ApplicationTeamRoleUpdateDataDto | null {
    if (this.editRole && this.editRoleForm) {
      const out: ApplicationTeamRoleUpdateDataDto = {
        uniqueKey: '',
        teamId: 0,
        mnemonic: '',
      };
      for (const field of this.editRoleForm) {
        if (field.key === 'teamId') {
          out.teamId = this.teams.find(team => team.teamName === field.value)?.teamId as number;
        } else {
          out[field.key as 'mnemonic' | 'uniqueKey'] = field.value;
        }
      }
      return out;
    }
    return null;
  }

  get teams(): Team[] {
    return TeamsModule.teams;
  }

  updateRole() {
    if (this.editRoleFormData) {
      updateApplicationTeamRole(this.editRoleFormData)
        .then(() => {
          this.editRoleOpen = false;
          this.load();
        })
        .catch(err => {
          openErrorSnackbar.call(this, err.response?.data?.message || err.response?.data?.error || err.toString());
        });
    }
  }

  private deleteId = '';
  private deleteOpen = false;

  deleteTeamRole() {
    deleteApplicationTeamRole(this.deleteId)
      .then(response => {
        const success = response.data;
        if (!success) {
          openErrorSnackbar.call(this, 'Cannot delete role: server delete function returned false');
        }
        this.deleteOpen = false;
        this.deleteId = '';
        this.load();
      })
      .catch(err => {
        openErrorSnackbar.call(this, err.response?.data?.message || err.response?.data?.error || err.toString());
      });
  }

  setDeleteRole(role: ApplicationTeamRole) {
    this.deleteOpen = true;
    this.deleteId = role.uniqueKey;
  }

  // From Approval.vue
  async load() {
    this.roles = getApplicationTeamRoles()
      .then(r => r.data)
      .catch((e: AxiosError) => {
        // format and re-throw error so vue-promise catches
        if (e && e.response) {
          throw e.response.data.message;
        }
        throw e;
      });
    await TeamsModule.getAllTeams();
    const teamField = this.newRoleForm.find(f => f.key === 'team') as AutoCompleteField<'team'>;
    teamField.choices = this.teams.map(team => ({
      label: team.teamName,
      value: String(team.teamId)
    }));
  }
  created() {
    this.load();
  }
}
